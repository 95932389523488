// Components
import accordion from './components/accordion';
import alert from './components/alert';
import navbar from './components/navbar';
import sliders from './components/sliders';
import readMore from './components/read-more';
import subscribe from './components/subscribe';
import tooltip from './components/tooltip';

// Forms
import file from './forms/file/index';
import select from './forms/select';
import dropdown from './forms/dropdown';
import validation from './forms/validation';
import datepicker from './forms/datepicker';

// Components
accordion();
alert();
navbar();
sliders();
readMore();
subscribe();
tooltip();

// Forms
file();
select();
dropdown(); // Never place above select!
validation();
datepicker();
