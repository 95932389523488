export default () => {
  const navbar = document.querySelector('.navbar__bottom');

  if (!navbar) return false;

  const toggles = navbar.querySelectorAll('.btn-toggle');
  const toggleMenu = navbar.querySelector('.navbar__toggle-menu');
  const hasSubs = navbar.querySelectorAll('[class^="has-sub"]');
  const navBacks = navbar.querySelectorAll('.nav-back');

  Array.from(toggles).forEach((el) => {
    el.addEventListener('click', () => {
      if (window.matchMedia('(pointer: coarse)').matches) {
        el.classList.toggle('open');
      }
    });
  });

  toggleMenu.addEventListener('click', () => {
    document.body.classList.toggle('nav-open');

    if (document.body.classList.contains('nav-open')) {
      Array.from(hasSubs).forEach((el) => {
        el.parentElement.classList.remove('open');
      });
    }
  });

  function openFirstHasSubsub(el) {
    const firstHasSubsub = el.querySelector('.has-subsub:first-child');

    if (firstHasSubsub) {
      firstHasSubsub.classList.add('open');
    }
  }

  function closeHasSubsubOpen() {
    const hasSubsubOpen = document.querySelector('.has-subsub.open');

    if (hasSubsubOpen) {
      hasSubsubOpen.classList.remove('open');
    }
  }

  Array.from(hasSubs).forEach((el) => {
    const navLink = el.querySelector(':scope > .nav-link');

    navLink.addEventListener('click', (e) => {
      if (window.matchMedia('(pointer: coarse)').matches) {
        closeHasSubsubOpen();

        el.classList.toggle('open');

        if (el.classList.contains('has-sub')) {
          openFirstHasSubsub(el);

          if (el.classList.contains('open')) {
            document.body.classList.add('sub-open');
          } else {
            document.body.classList.remove('sub-open');
          }
        }

        if (el.classList.contains('has-subsub')) {
          e.preventDefault();
        }
      }
    });

    navLink.addEventListener('mouseover', () => {
      if (window.matchMedia('(pointer: fine)').matches) {
        if (el.classList.contains('has-sub')) {
          openFirstHasSubsub(el);
        }
      }
    });

    el.addEventListener('mouseover', () => {
      if (window.matchMedia('(pointer: fine)').matches) {
        if (el.classList.contains('has-sub')) {
          document.body.classList.add('sub-open');
        }

        if (el.classList.contains('has-subsub')) {
          closeHasSubsubOpen();
        }

        el.classList.add('open');
      }
    });

    el.addEventListener('mouseout', () => {
      if (window.matchMedia('(pointer: fine)').matches) {
        if (el.classList.contains('has-sub')) {
          closeHasSubsubOpen();

          el.classList.remove('open');

          document.body.classList.remove('sub-open');
        }
      }
    });
  });

  Array.from(navBacks).forEach((el) => {
    el.addEventListener('click', () => {
      el.parentElement.parentElement.classList.remove('open');
      el.parentElement.previousElementSibling.classList.remove('open');
    });
  });
};
