import AccordionItem from './accordion-item';

export default class AccordionWrap {
  constructor(selector) {
    this.container = selector;
    this.openedItem = [];
    this.items = [];

    this.initiateAccordionItems();
  }

  initiateAccordionItems() {
    this.container.querySelectorAll('details').forEach((accordionItem, index) => {
      this.items.push(new AccordionItem(accordionItem, index, this));
    });
  }
}
