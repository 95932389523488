export default function alert() {
  const alerts = document.querySelectorAll('.alert-dismissible');

  if (!alerts.length) return;

  Array.from(alerts).forEach((el) => {
    const closeBtn = el.querySelector('.btn-close');

    closeBtn.addEventListener('click', () => {
      el.parentNode.removeChild(el);
    });
  });
}
