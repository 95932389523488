import flatpickr from 'flatpickr';
import { Dutch } from 'flatpickr/dist/l10n/nl';

export default () => {
  const datepickers = document.querySelectorAll('input[type*="date"]');

  if (!datepickers.length > 0) return false;

  const today = new Date();

  function weekends(date) {
    return (date.getDay() === 0 || date.getDay() === 6);
  }

  Array.from(datepickers).forEach((el) => {
    let time = '';
    let format = '';

    if (el.type.includes('time')) {
      time = true;
      format = 'd-m-Y H:i';
    } else {
      time = false;
      format = 'd-m-Y';
    }

    flatpickr(el, {
      locale: Dutch,
      altInput: true,
      altFormat: format,
      enableTime: time,
      time_24hr: true,
      minDate: today.setDate(today.getDate() + 1),
      disable: [
        weekends,
      ],
    });
  });
};
