export default () => {
  const button = document.querySelector('.toggle-subscribe-popup');

  if (!button) return;

  button.addEventListener('click', () => {
    const popup = document.querySelector('#mp-subdiv1');

    if (!popup) return;

    popup.style.display = 'block';
    popup.style.left = '50px';
    popup.style.bottom = '50px';
  });
};
