import { show, hide } from '../util/show-hide';
import getParents from '../util/get-parents';

const classFormSelect = 'form-select';
const classFormSelectOpen = 'active';
const classFormSelectBtn = 'form-select__btn';
const classFormSelectList = 'form-select__list';
const classFormInit = 'initialized';

export default () => {
  const dropdowns = document.querySelectorAll(`.${classFormSelect}:not(.${classFormInit})`);

  if (!dropdowns.length) return;

  const closeOpenDropdowns = () => {
    const openDropdown = document.querySelector(`.${classFormSelect}.${classFormSelectOpen}`);

    if (openDropdown) {
      const openList = openDropdown.querySelector(`.${classFormSelectList}`);

      hide(openList);
      openDropdown.classList.remove(classFormSelectOpen);
    }
  };

  const buildDropdown = () => {
    Array.from(dropdowns).forEach((dropdown) => {
      const btn = dropdown.querySelector(`.${classFormSelectBtn}`);
      const list = dropdown.querySelector(`.${classFormSelectList}`);

      if (!btn) return;

      dropdown.classList.add(classFormInit);

      btn.addEventListener('click', () => {
        if (list.classList.contains('show')) {
          hide(list);
          dropdown.classList.remove(classFormSelectOpen);
        } else {
          closeOpenDropdowns();
          dropdown.classList.add(classFormSelectOpen);
          show(list);
        }
      });
    });
  };

  buildDropdown();

  document.addEventListener('click', (ev) => {
    if (ev.target.classList.contains(classFormSelectBtn)
      || (getParents(ev.target, `.${classFormSelect}--checkbox`))
      || (getParents(ev.target, `.${classFormSelect}--range`))
    ) return;

    closeOpenDropdowns();
  });
};
