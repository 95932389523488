export default () => {
  const forms = document.querySelectorAll("form[novalidate]");
  if (!forms.length) return;
  Array.from(forms).forEach((form) => {
    form.addEventListener("submit", (e) => {
      e.preventDefault();
      const formIsValid = form.checkValidity();
      form.classList.add("was-validated");
      if (!formIsValid) {
        // Add error handling here
        console.error("Form validation failed");
        return;
      }
      form.submit();
    });
  });
};
