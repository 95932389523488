import getParents from '../../util/get-parents';
import Selector from './selector';

const getDefaultText = (input) => {
  let defaultText = '';

  const parent = getParents(input, Selector.formFile);
  const text = parent.querySelector(Selector.formFileText);

  defaultText = text.textContent;

  return defaultText;
};

const restoreDefaultText = (input) => {
  const { defaultText } = input.customFileInput;
  const parent = getParents(input, Selector.formFile);
  const text = parent.querySelector(Selector.formFileText);

  parent.classList.remove('active');
  text.textContent = defaultText;
};

export {
  getDefaultText,
  restoreDefaultText,
};
