import scrollTo from './scroll-to';

export default () => {
  const readMoreBtns = document.querySelectorAll('.read-more');

  if (!readMoreBtns.length) return;

  Array.from(readMoreBtns).forEach((el) => {
    const target = document.querySelector(el.dataset.target);

    el.addEventListener('click', () => {
      scrollTo(target);
    });
  });
};
