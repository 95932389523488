export default (target) => {
  const navbarOffset = document.querySelector('.navbar-main').offsetHeight;

  if (!target) return;

  const targetOffsetTop = (target.getBoundingClientRect().top + window.scrollY) - navbarOffset;

  window.scrollTo({
    top: targetOffsetTop,
    behavior: 'smooth',
  });
};
