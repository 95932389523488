/* eslint-disable no-unused-vars */

import { tns } from 'tiny-slider/src/tiny-slider';

const sliderUsp = () => {
  const sliderInstance = document.querySelector('.usps ul');

  if (!sliderInstance) return;

  const slider = tns({
    container: sliderInstance,
    axis: 'vertical',
    autoplay: true,
    autoplayButtonOutput: false,
    loop: true,
    nav: false,
    touch: false,
    controls: false,
    responsive: {
      1024: {
        disable: true,
      },
    },
  });
};

const sliderBlog = () => {
  const sliderInstance = document.querySelector('.blog-slider');

  if (!sliderInstance) return;

  const slider = tns({
    container: sliderInstance,
    items: 1,
    mouseDrag: true,
    gutter: 24,
    navPosition: 'bottom',
    loop: false,
    controls: false,
    responsive: {
      568: {
        items: 2,
      },
      1024: {
        items: 3,
      },
    },
  });
};

const sliderLogo = () => {
  const sliderInstance = document.querySelector('.logo-slider');

  if (!sliderInstance) return;

  const slider = tns({
    container: sliderInstance.querySelector('.logo-slider__track'),
    items: 4,
    mouseDrag: true,
    gutter: 10,
    navPosition: 'bottom',
    loop: false,
    controls: true,
    controlsContainer: sliderInstance.querySelector('.slider-controls'),
    responsive: {
      568: {
        items: 5,
      },
      1024: {
        items: 6,
        gutter: 24,
      },
    },
  });
};

const sliderReview = () => {
  const sliderInstance = document.querySelector('.review-slider');

  if (!sliderInstance) return;

  const slider = tns({
    container: sliderInstance.querySelector('.review-slider__track'),
    items: 1,
    mouseDrag: true,
    gutter: 20,
    navPosition: 'bottom',
    loop: false,
    controls: true,
    controlsContainer: sliderInstance.querySelector('.slider-controls'),
    edgePadding: 30,
    responsive: {
      568: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1024: {
        items: 4,
        gutter: 24,
      },
    },
  });
};

const sliderProduct = () => {
  const sliderInstances = document.querySelectorAll('.product-slider');

  if (!sliderInstances.length) return;

  Array.from(sliderInstances).forEach((instance) => {
    const slider = tns({
      container: instance.querySelector('.product-slider__track'),
      items: 1,
      mouseDrag: true,
      gutter: 20,
      navPosition: 'bottom',
      loop: false,
      controls: true,
      controlsContainer: instance.querySelector('.slider-controls'),
      edgePadding: 30,
      responsive: {
        568: {
          items: 2,
        },
        768: {
          items: 3,
          edgePadding: 0,
        },
        1024: {
          items: 4,
          gutter: 24,
        },
      },
    });
  });
};

const sliderProductView = () => {
  const sliderInstance = document.querySelector('.hero--product__slider__track');

  if (!sliderInstance) return;

  window.sliderProductView = tns({
    container: sliderInstance,
    items: 1,
    navContainer: '.thumbnail-slider__track',
    navAsThumbnails: true,
    mouseDrag: true,
    speed: 400,
    loop: false,
    controls: false,
  });
};

const sliderThumbnail = () => {
  const thumbnailSliders = document.querySelectorAll('.thumbnail-slider');

  if (!thumbnailSliders.length) return;

  Array.from(thumbnailSliders).forEach((el) => {
    const controls = el.querySelector('.thumbnail-slider__controls');

    if (controls) {
      const track = el.querySelector('.thumbnail-slider__track');
      const trackThumbFirst = track.querySelector('li');
      const controlsPrev = controls.querySelector('.prev');
      const controlsNext = controls.querySelector('.next');

      track.addEventListener('scroll', () => {
        const newScrollLeft = track.scrollLeft;
        const width = track.offsetWidth;
        const { scrollWidth } = track;
        const offset = 0;

        if (newScrollLeft === 0) {
          controlsPrev.style.display = 'none';
        }

        if (newScrollLeft > 0) {
          controlsPrev.style.display = 'flex';
          controlsNext.style.display = 'flex';
        }

        if (scrollWidth - newScrollLeft - width === offset) {
          controlsNext.style.display = 'none';
        }
      });

      controlsPrev.addEventListener('click', () => {
        const thumbnailWidth = trackThumbFirst.offsetWidth;

        track.scrollBy({
          left: -thumbnailWidth * 4,
          behavior: 'smooth',
        });
      });

      controlsNext.addEventListener('click', () => {
        const thumbnailWidth = trackThumbFirst.offsetWidth;

        track.scrollBy({
          left: thumbnailWidth * 4,
          behavior: 'smooth',
        });
      });
    }
  });
};

export default () => {
  sliderUsp();
  sliderBlog();
  sliderLogo();
  sliderReview();
  sliderProduct();
  sliderProductView();
  sliderThumbnail();
};
