const getHeight = (element, px = true) => {
  let height;
  element.style.display = 'block';

  if (px) {
    height = `${element.offsetHeight}px`;
  } else {
    height = `${element.offsetHeight}`;
  }
  element.style.display = '';
  return height;
};

const show = (element) => {
  if (element.classList.contains('show')) return;

  const elementsHeight = getHeight(element);

  element.classList.remove('collapse');
  element.classList.add('collapsing');

  element.style.height = 0;

  const complete = () => {
    element.classList.remove('collapsing');
    element.classList.add('collapse');
    element.classList.add('show');
    element.removeAttribute('style');
  };

  setTimeout(complete, 350);

  setTimeout(() => {
    element.style.height = elementsHeight;
  }, 1);
};

const hide = (element) => {
  if (!element.classList.contains('show')) return;

  element.style.height = `${element.offsetHeight}px`;

  element.classList.add('collapsing');
  element.classList.remove('collapse');
  element.classList.remove('show');

  setTimeout(() => {
    element.style.height = 0;
  }, 1);

  const complete = () => {
    element.classList.remove('collapsing');
    element.classList.add('collapse');
    element.removeAttribute('style');
  };

  setTimeout(complete, 350);
};

export { getHeight, show, hide };
