import { getDefaultText } from './util';
import { handleFormReset, handleInputChange, handleBtnClick } from './event-handlers';
import Selector from './selector';

export default () => {
  const formFileList = document.querySelectorAll(Selector.formFile);

  if (!formFileList.length) return;

  const customProperty = 'customFileInput';
  const formList = document.querySelectorAll(Selector.form);

  Array.from(formFileList).forEach((el) => {
    const input = el.querySelector(Selector.formFileInput);
    const btn = el.querySelector(Selector.formFileBtn);

    Object.defineProperty(input, customProperty, {
      value: {
        defaultText: getDefaultText(input),
      },
      writable: true,
    });

    input.addEventListener('change', () => {
      handleInputChange.call(input);
    });

    btn.addEventListener('click', () => {
      handleBtnClick.call(input);
    });
  });

  Array.from(formList).forEach((el) => {
    el.addEventListener('reset', () => {
      handleFormReset.call(el);
    });

    Object.defineProperty(el, customProperty, {
      value: true,
      writable: true,
    });
  });
};
