import getParents from '../util/get-parents';

export default () => {
  const classFormSelect = 'form-select';
  const formSelects = document.querySelectorAll(`.${classFormSelect}:not(.form-select--custom)`);

  if (!formSelects.length) return;

  const classFormSelectInner = 'form-select__inner';
  const classFormSelectBtn = 'form-select__btn';
  const classFormSelectList = 'form-select__list';
  const classFormSelectListBody = 'form-select__list__body';
  const classFormSelectListScroller = 'form-select__list__scroller';
  const classFormSelectOption = 'form-select__option';
  const classEqualsSelected = 'equals-selected';

  const goToUrlOnChangeSelect = (url) => {
    if (url.indexOf('//') !== -1) {
      window.location = url;
    } else if (url.indexOf('#') !== -1) {
      const id = url.replace('#', '');
      const target = document.getElementById(id);
      const navbarOffset = document.querySelector('.navbar').offsetHeight;

      if (target) {
        const targetOffsetTop = (target.getBoundingClientRect().top + window.scrollY) - navbarOffset - 25;

        window.scrollTo({
          top: targetOffsetTop,
          behavior: 'smooth',
        });
      }
    }
  };

  const buildCustomSelect = () => {
    Array.from(formSelects).forEach((formSelect) => {
      const innerExists = formSelect.querySelector(`.${classFormSelectList}`);

      if (innerExists) return;

      const selElmnt = formSelect.getElementsByTagName('select')[0];
      const inner = document.createElement('div');
      const selectedItem = document.createElement('div');
      const selectedItemText = document.createElement('span');
      const selectedItemDataset = selElmnt.options[selElmnt.selectedIndex].dataset;

      inner.setAttribute('class', classFormSelectInner);
      selectedItem.setAttribute('class', classFormSelectBtn);
      formSelect.setAttribute('role', 'listbox');
      formSelect.setAttribute('aria-label', 'Select Wrapper');
      selectedItem.setAttribute('tabindex', '0');

      selectedItemText.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
      selectedItem.appendChild(selectedItemText);

      if (selectedItemDataset.img) {
        const fig = document.createElement('figure');
        const img = document.createElement('img');

        img.src = selectedItemDataset.img;

        fig.prepend(img);
        selectedItem.prepend(fig);
      } else if (selectedItemDataset.icon || selectedItemDataset.color) {
        const icon = document.createElement('i');

        if (selectedItemDataset.icon) {
          icon.classList.add(selectedItemDataset.icon);
        } else if (selectedItemDataset.color) {
          icon.classList.add('icon-dot');
          icon.style.color = selectedItemDataset.color;
        }

        selectedItem.prepend(icon);
      }

      const classItemI = selElmnt.options[selElmnt.selectedIndex].classList;
      selElmnt.insertAdjacentElement('afterend', inner);
      inner.appendChild(selectedItem);

      if (classItemI.length) {
        selectedItem.classList.add(classItemI.value);
      }

      const items = document.createElement('div');
      const innerItems = document.createElement('div');
      const innerScroller = document.createElement('div');

      items.setAttribute('class', `${classFormSelectList} collapse`);
      innerItems.setAttribute('class', classFormSelectListBody);
      innerScroller.setAttribute('class', classFormSelectListScroller);
      innerItems.appendChild(innerScroller);
      items.appendChild(innerItems);

      if (selElmnt.length <= 1) {
        formSelect.classList.add('disabled');
      }

      for (let j = 0; j < selElmnt.length; j += 1) {
        if (!selElmnt.options[j].disabled) {
          const item = document.createElement('div');
          const itemText = document.createElement('span');
          const itemJclassList = selElmnt.options[j].classList;
          const itemJdataset = selElmnt.options[j].dataset;

          item.setAttribute('class', `${classFormSelectOption}`);
          item.setAttribute('tabindex', '-1');
          item.setAttribute('role', 'option');
          item.setAttribute('data-value', selElmnt.options[j].value);

          if (selElmnt.options[j].disabled) {
            item.classList.add('disabled');
          }

          if (itemJclassList.length) {
            item.classList.add(itemJclassList.value);
          }

          itemText.innerHTML = selElmnt.options[j].innerHTML;
          item.appendChild(itemText);

          if (itemJdataset.img) {
            const fig = document.createElement('figure');
            const img = document.createElement('img');

            img.src = itemJdataset.img;

            fig.prepend(img);
            item.prepend(fig);
          } else if (itemJdataset.icon || itemJdataset.color) {
            const icon = document.createElement('i');

            if (itemJdataset.icon) {
              icon.classList.add(itemJdataset.icon);
            } else if (itemJdataset.color) {
              icon.classList.add('icon-dot');
              icon.style.backgroundColor = itemJdataset.color;
            }

            item.prepend(icon);
          }

          if (selElmnt.getAttribute('data-value') === item.value || selElmnt.selectedOptions[0].label.toLowerCase().replace(/\s/g, '') === item.innerText.toLowerCase().replace(/\s/g, '')) {
            item.classList.add(classEqualsSelected);

            if (itemJclassList.length) {
              item.classList.add(itemJclassList.value);
            }
          }

          const setSelectedListItem = (itemEl) => {
            let y;
            let isub;
            let k;
            const h = getParents(itemEl, `.${classFormSelectList}`).previousSibling;
            const s = getParents(itemEl, `.${classFormSelect}`).getElementsByTagName('select')[0];

            for (isub = 0; isub < s.length; isub += 1) {
              if (itemEl.getAttribute('data-value') === s.options[isub].value || itemEl.innerText.toLowerCase().replace(/\s/g, '') === s.options[isub].innerHTML.toLowerCase().replace(/\s/g, '')) {
                s.selectedIndex = isub;
                s.dispatchEvent(new Event('change'));
                h.innerHTML = itemEl.innerHTML;
                y = itemEl.parentElement.getElementsByClassName(classEqualsSelected);

                for (k = 0; k < y.length; k += 1) {
                  y[k].classList.remove(classEqualsSelected);
                }

                itemEl.classList.add(classEqualsSelected);

                break;
              }
            }

            goToUrlOnChangeSelect(s.value);
          };

          if (!selElmnt.options[j].disabled) {
            item.addEventListener('click', () => {
              setSelectedListItem(item);
            });
          }

          innerScroller.appendChild(item);
        }
      }

      inner.appendChild(items);

      selectedItem.addEventListener('click', (e) => {
        e.stopPropagation();
      });
    });
  };

  buildCustomSelect();
};
