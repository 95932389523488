import getParents from '../../util/get-parents';
import { restoreDefaultText } from './util';
import Selector from './selector';

const fileApi = !!window.File;
const FAKE_PATH = 'fakepath';
const FAKE_PATH_SEPARATOR = '\\';

const getSelectedFiles = (input) => {
  if (input.hasAttribute('multiple') && fileApi) {
    return [].slice.call(input.files)
      .map((file) => file.name)
      .join(', ');
  }

  if (input.value.indexOf(FAKE_PATH) !== -1) {
    const splittedValue = input.value.split(FAKE_PATH_SEPARATOR);

    return splittedValue[splittedValue.length - 1];
  }

  return input.value;
};

function handleInputChange() {
  const parent = getParents(this, Selector.formFile);
  const text = parent.querySelector(Selector.formFileText);
  const inputValue = getSelectedFiles(this);

  if (inputValue.length) {
    parent.classList.add('active');
    text.textContent = inputValue;
  } else {
    restoreDefaultText(this);
  }
}

function handleBtnClick() {
  this.value = '';
  restoreDefaultText(this);
}

function handleFormReset() {
  const inputList = this.querySelectorAll(Selector.formFileInput);

  Array.from(inputList).forEach((el) => {
    restoreDefaultText(el);
  });
}

export {
  handleInputChange,
  handleBtnClick,
  handleFormReset,
};
