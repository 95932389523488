import getParents from './get-parents';

export default class AccordionItem {
  constructor(el, index, container) {
    // Store the <details> element
    this.el = el;
    this.index = index;
    this.container = container;
    // Store the <summary> element
    this.summary = el.querySelector('summary');
    // Store the <div class="content"> element
    this.content = el.querySelector('[class*="body"]');

    // Store the animation object (so we can cancel it if needed)
    this.animation = null;
    // Store if the element is closing
    this.isClosing = false;
    // Store if the element is expanding
    this.isExpanding = false;
    // Store if id needs to be set as hashtag
    this.useIdAsHashtag = (this.el.getAttribute('data-use-id-as-hashtag') === '1');

    this.initialize();
  }

  initialize() {
    // Detect user clicks on the summary element
    this.summary.addEventListener('click', (e) => this.handleClick(e));
    // Open accordion when by hashtag in url
    if (window.location.hash.replace('#', '') !== '' && window.location.hash.replace('#', '') === this.el.id) {
      this.el.scrollIntoView(true);
      this.open();
    }
  }

  handleClick(e) {
    // Stop default behaviour from the browser
    e.preventDefault();
    // Check if the element is being closed or is already closed
    if (this.isClosing || !this.el.open) {
      this.open();
      // Check if the element is being openned or is already open
    } else if (this.isExpanding || this.el.open) {
      this.shrink();
    }
  }

  shrink() {
    // Add an overflow on the <details class="accordion"> to avoid content overflowing
    this.el.style.overflow = 'hidden';

    this.el.classList.remove('open');

    // Set the element as "being closed"
    this.isClosing = true;

    // Store the current height of the element
    const startHeight = `${this.el.offsetHeight}px`;
    // Calculate the height of the summary
    const endHeight = `${this.summary.offsetHeight}px`;

    // If there is already an animation running
    if (this.animation) {
      // Cancel the current animation
      this.animation.cancel();
    }

    // Start a WAAPI animation
    this.animation = this.el.animate({
      // Set the keyframes from the startHeight to endHeight
      height: [startHeight, endHeight],
    }, {
      duration: 400,
      easing: 'ease-out',
    });

    // When the animation is complete, call onAnimationFinish()
    this.animation.onfinish = () => this.onAnimationFinish(false);
    // If the animation is cancelled, isClosing variable is set to false
    this.animation.oncancel = () => this.isClosing === false;
  }

  open() {
    const wrap = getParents(this.el, '.accordion-wrap');

    // Add an overflow on the <details class="accordion"> to avoid content overflowing
    this.el.style.overflow = 'hidden';

    if (!wrap.classList.contains('multi-open')) {
      const openedItem = this.container.items.find((item) => item.el.open === true);

      if (openedItem) {
        openedItem.shrink();
      }
    }

    // Apply a fixed height on the element
    this.el.style.height = `${this.el.offsetHeight}px`;
    // Force the [open] attribute on the details element
    this.el.open = true;
    this.el.classList.add('open');
    // Wait for the next frame to call the expand function
    window.requestAnimationFrame(() => this.expand());
  }

  expand() {
    // Set the element as "being expanding"
    this.isExpanding = true;
    // Get the current fixed height of the element
    const startHeight = `${this.el.offsetHeight}px`;
    // Calculate the open height of the element (summary height + content height)
    const endHeight = `${this.summary.offsetHeight + this.content.offsetHeight}px`;

    // If there is already an animation running
    if (this.animation) {
      // Cancel the current animation
      this.animation.cancel();
    }

    // Start a WAAPI animation
    this.animation = this.el.animate({
      // Set the keyframes from the startHeight to endHeight
      height: [startHeight, endHeight],
    }, {
      duration: 400,
      easing: 'ease-out',
    });
    // When the animation is complete, call onAnimationFinish()
    this.animation.onfinish = () => this.onAnimationFinish(true);
    // If the animation is cancelled, isExpanding variable is set to false
    this.animation.oncancel = () => this.isExpanding === false;
  }

  onAnimationFinish(open) {
    // Set the open attribute based on the parameter
    this.el.open = open;
    // Clear the stored animation
    this.animation = null;
    // Reset isClosing & isExpanding
    this.isClosing = false;
    this.isExpanding = false;
    // Remove the overflow hidden and the fixed height
    this.el.style.height = '';
    this.el.style.overflow = '';

    if (this.useIdAsHashtag && this.el.id) {
      window.location.hash = this.el.id;
    }
  }
}
