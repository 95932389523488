import AccordionWrap from '../util/accordion-wrap';

export default () => {
  const wraps = document.querySelectorAll('.accordion-wrap');

  if (!wraps.length) return;

  wraps.forEach((el) => {
    new AccordionWrap(el);
  });
};
