import { createPopper } from '@popperjs/core';

export default () => {
  const tooltips = document.querySelectorAll('.tooltip');

  if (!tooltips.length) return;

  function show(tooltip, popperInstance) {
    // Make the tooltip visible
    tooltip.setAttribute('data-show', '');

    // Enable the event listeners
    popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        {
          name: 'eventListeners',
          enabled: true,
        },
      ],
    }));

    // Update its position
    popperInstance.update();
  }

  function hide(tooltip, popperInstance) {
    // Hide the tooltip
    tooltip.removeAttribute('data-show');

    // Disable the event listeners
    popperInstance.setOptions((options) => ({
      ...options,
      modifiers: [
        ...options.modifiers,
        {
          name: 'eventListeners',
          enabled: false,
        },
      ],
    }));
  }

  const showEvents = ['mouseenter', 'focus'];
  const hideEvents = ['mouseleave', 'blur'];

  Array.from(tooltips).forEach((tooltip) => {
    const button = tooltip.parentElement.querySelector('[aria-describedby="tooltip"]');

    const popperInstance = createPopper(button, tooltip, {
      placement: 'bottom',
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      }],
    });

    showEvents.forEach((event) => {
      button.addEventListener(event, () => {
        show(tooltip, popperInstance);
      });
    });

    hideEvents.forEach((event) => {
      button.addEventListener(event, () => {
        hide(tooltip, popperInstance);
      });
    });
  });
};
